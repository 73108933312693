import React from "react";
import "./LlamaQuery.css";
import Header from "../Header";
import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, post_request } from "../requests";

const LlamaQueryScreen = () => {
  const [query, set_query] = useState("");
  const [max_tokens, set_max_tokens] = useState("512");
  const [model_name, set_model_name] = useState("gpt-4");
  const [temp, set_temp] = useState("0");
  const [search_dir, set_search_dir] = useState("");
  const [submit_disabled, set_submit_disabled] = useState(false);
  const [search_result, set_search_result] = useState("");
  const [error_msg, set_error_msg] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const fetch_query_result = useCallback(async () => {
    set_search_result("");
    set_error_msg("");
    set_submit_disabled(true)
    if (query === "" || search_dir === "") {
      set_error_msg("Must enter 'Folder Name' and 'Prompt'")
      set_submit_disabled(false)
      return
    }
    const token = await getAccessTokenSilently();
    const endpoint = endpoints.post_query_llama;
    const body = { "query": query, "search_dir": search_dir };
    if (max_tokens !== "") {
      body["max_tokens"] = max_tokens;
    }

    if (model_name !== "") {
      body["model_name"] = model_name;
    }

    if (temp !== "") {
      body["temp"] = temp;
    }
    set_submit_disabled(true)
    set_search_result("Waiting for response...")
    const response_data = await post_request(endpoint, token, body);
    if (response_data.is_success) {
      set_search_result(response_data.body);
      set_error_msg("");
      set_submit_disabled(false)
    } else {
      set_error_msg(response_data.error)
      set_search_result("");
      set_submit_disabled(false)
    }
  }, [getAccessTokenSilently, query, search_dir, max_tokens, model_name, temp])

  function on_query_change(event) {
    set_query(event.target.value);
  }
  function on_search_dir_change(event) {
    set_search_dir(event.target.value);
  }
  function on_max_tokens_change(event) {
    set_max_tokens(event.target.value);
  }
  function on_model_name_change(event) {
    set_model_name(event.target.value);
  }
  function on_temp_change(event) {
    set_temp(event.target.value);
  }

  return (
    <div className="llama_query_screen">
      <Header />
      <div className="llama_query_screen_content">
        <h1>AI Query</h1>
        <div className="llama_query_folder_name">
          Index Group Name:
          <input
            className="llama_query_folder_name_tbox"
            type="text"
            autocomplete="off"
            data-form-type="other"
            data-lpignore="true"
            value={search_dir}
            onChange={on_search_dir_change}
          />
        </div>
        <div className="llama_query_max_tokens">
          Max Response Tokens:
          <input
            className="llama_query_max_tokens_tbox"
            type="text"
            autocomplete="off"
            data-lpignore="true"
            value={max_tokens}
            onChange={on_max_tokens_change}
          />
        </div>
        <div className="llama_query_model_name">
          Model Name:
          <input
            className="llama_query_model_name_tbox"
            type="text"
            autocomplete="off"
            data-form-type="other"
            data-lpignore="true"
            value={model_name}
            onChange={on_model_name_change}
          />
        </div>
        <div className="llama_query_temp">
          Temperature:
          <input
            className="llama_query_temp_tbox"
            type="text"
            autocomplete="off"
            data-lpignore="true"
            value={temp}
            onChange={on_temp_change}
          />
        </div>
        <br />
        <div className="llama_query_prompt">
          <div className="llama_query_prompt_title">
            Prompt:
          </div>
          <textarea
            className="llama_query_prompt_tbox"
            type="text"
            value={query}
            onChange={on_query_change}
          />
        </div>
        <button className="llama_query_submit_btn" disabled={submit_disabled} onClick={fetch_query_result}>Submit</button>
        <br />
        <div className="llama_query_search_result">{search_result}</div>
        <div className="llama_query_error_result">{error_msg}</div>
      </div>
    </div>
  );
};

export default LlamaQueryScreen;
