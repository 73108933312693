import React from "react";
import "./HomeScreen.css";
import Header from "../Header";

const HomeScreen = () => {
  return (
    <div className="homeScreen">
      <Header />
      <div className="homeScreen_content">
        Welcome.
      </div>
    </div>
  );
};

export default HomeScreen;
