const base_url = process.env.REACT_APP_BACKEND_ENDPOINT;


export class ResponseData {
  constructor(is_success, body, error) {
    this.is_success = is_success;
    this.body = body;
    this.error = error;
  }
}

export const get_request = async (endpoint, token) => {
  let url = base_url + endpoint
  const request_params = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }
  };
  return fetch_with_params(url, request_params)
};

export const post_request = async (endpoint, token, body) => {
  let url = base_url + endpoint
  const request_params = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token },
    body: JSON.stringify(body)
  };
  return fetch_with_params(url, request_params)
};

export const post_multi_part_form = async (endpoint, token, body) => {
  let url = base_url + endpoint
  const request_params = {
    method: 'POST',
    headers: { 'authorization': 'Bearer ' + token },
    body: body
  };
  return fetch_with_params(url, request_params)
};

const fetch_with_params = async (url, request_params) => {
  try {
    const response = await fetch(url, request_params);
    if (response.status >= 200 && response.status <= 299) {
      const response_json = await response.json();
      console.log(response_json);
      return new ResponseData(true, response_json, null);
    } else {
      console.log(response.status, response.statusText);
      let error_msg = await response.text();
      console.error(error_msg);
      return new ResponseData(false, null, error_msg);
    }
  }
  catch (e) {
    const error_msg = "Network error. Unable to send request to " + url
    console.error(error_msg + " " + e);
    return new ResponseData(false, null, error_msg)
  }
};

export const endpoints = {
  post_query_llama: '/query_llama',
  post_upload: '/upload',
  get_created_folders: '/created_folders'
};

export default ResponseData;
