import React from "react";
import "./FileUpload.css";
import Header from "../Header";
import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { endpoints, post_multi_part_form, get_request } from "../requests";

const FileUploadScreen = () => {

  const [file, setFile] = useState(null)
  const [folder, set_folder] = useState("");
  const [upload_disabled, set_upload_disabled] = useState(false);
  const [upload_result, set_upload_result] = useState("");
  const [error_msg, set_error_msg] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  function handleChange(event) {
    event.preventDefault()
    setFile(event.target.files[0])
  }
  function on_folder_change(event) {
    event.preventDefault()
    set_folder(event.target.value);
  }

  const on_form_submit = useCallback(async () => {
    set_upload_result("");
    set_error_msg("");
    set_upload_disabled(true)
    if (file === null || folder === "") {
      set_error_msg("Invlid Form Data")
      set_upload_disabled(false)
      return
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folder);
    formData.append('fileName', file.name);
    const token = await getAccessTokenSilently();
    const endpoint = endpoints.post_upload;
    const response_data = await post_multi_part_form(endpoint, token, formData);
    if (response_data.is_success) {
      set_upload_result("File successfully uploaded.");
      setFile(null);
      set_upload_disabled(false)
    } else {
      set_error_msg("Upload Failed. Try Again.")
      set_upload_disabled(false)
    }
  }, [getAccessTokenSilently, file, folder])

  const on_check_folder_names = useCallback(async () => {
    set_upload_result("");
    set_error_msg("");
    const token = await getAccessTokenSilently();
    const endpoint = endpoints.get_created_folders;
    const response_data = await get_request(endpoint, token);
    if (response_data.is_success) {
      const folders_lst = response_data.body["folders"];
      let folders = [];
      for (var i = 0; i < folders_lst.length; i++) {
        folders.push(" " + folders_lst[i]);
      }
      console.log(folders)
      set_upload_result("Existing folder names: " + folders);
    } else {
      set_error_msg("API Call Failed. Try Again.")
    }
  }, [getAccessTokenSilently])

  return (
    <div className="file_upload_screen">
      <Header />
      <div className="file_upload_screen_content">
        <h1>File Upload</h1>
        <input type="file" onChange={handleChange} />
        Index Group Name:
        <input
          type="text"
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          value={folder}
          onChange={on_folder_change}
        />
        <button type="submit" disabled={upload_disabled} className="file_upload_check_button" onClick={on_check_folder_names}>All Index Groups</button>
        <br />

        <button type="submit" disabled={upload_disabled} className="file_upload_upload_button" onClick={on_form_submit}>Upload</button>
        <div className="file_upload_upload_result">{upload_result}</div>
        <div className="file_upload_error_result">{error_msg}</div>
      </div>
    </div>
  );
}

export default FileUploadScreen;
