import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import LlamaQueryScreen from "./screens/LlamaQuery";
import FileUploadScreen from "./screens/FileUpload";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/llama_query" component={LlamaQueryScreen} />
          <Route exact path="/file_upload" component={FileUploadScreen} />
        </Switch>
      </Router>
    </div>
  );
}

//export default App;
export default withAuthenticationRequired(App);
