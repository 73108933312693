import "./Nav.css";

const NavBar = () => {
  return (
    <div className="navBar">
      <div className="navBar_contents">
        <ul>
          <li className="navBar_title">
            <a href="/llama_query">AI Query</a>
          </li>
          <li className="navBar_title">
            <a href="/file_upload">File Upload</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
